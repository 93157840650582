(function () {
    'use strict';

    angular.module('aerosApp')
        .factory('aflGridDeletConfirmModal', aflGridDeletConfirmModal)
        .factory('aflToolTypeModal', aflToolTypeModal);

    aflGridDeletConfirmModal.$inject = ['$uibModal'];
    function aflGridDeletConfirmModal($uibModal) {
        return {open: open};

        function open(model) {
            return $uibModal.open({
                size: "md",
                templateUrl: '/static/templates/tools/confirmDeleteModal.html',
                controller: ["$scope", function ($scope) {
                    $scope.model = model;
                }]
            }).result;
        }
    }

    aflToolTypeModal.$inject = ['$uibModal', 'aerosApi', '$stateParams'];
    function aflToolTypeModal($uibModal, aerosApi, $stateParams) {
        return {open: open};

        function open() {
            return $uibModal.open({
                size: "md",
                templateUrl: '/static/templates/tools/toolTypeModal.html',
                resolve: {
                    userInfo: function () {
                        return aerosApi.loadOrganizationUserInfo($stateParams.id).then(function (d) {
                            return d.data
                        });
                    }
                },
                controller: ['$scope', 'userInfo', function ($scope, userInfo) {
                    $scope.toolTypes = userInfo.organization.features;
                    $scope.toolType = $scope.toolTypes[0];
                }]
            }).result;
        }
    }

}());